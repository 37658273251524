import React from 'react';
import { connect } from 'react-redux';
import { Record, List } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGlobe, faUser, faLock } from '@fortawesome/free-solid-svg-icons'
import { clearAuthentication } from '@pearlchain/component-lib-common';
import { history } from '../App';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardImg,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    FormGroup,
    FormFeedback
} from 'reactstrap';

import pearlchain_logo from '../img/pearlchain_logo.png';
import { login, pickRealm, reset } from '../actions/loginActions';
import { Realm } from '../types/loginTypes';
import { getUiState } from '../selectors/loginSelectors';

library.add(faGlobe as any, faUser as any, faLock as any);

interface Props {
    realms: List<Record<Realm>>|undefined,
    token: string|undefined,
    fetching: boolean,
    success: boolean,
    error: string|undefined,
    login: typeof login,
    pickRealm: typeof pickRealm,
    reset: typeof reset,
};

interface State {
    userName: string,
    password: string,
    realm: string,
    touchedUsername: boolean,
    touchedPassword: boolean,
    touchedRealm: boolean
}

class LoginForm extends React.Component<Props, State> {
    private realmSelectEl: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);

        this.state = {
            userName: '',
            password: '',
            realm: '',
            touchedUsername: false,
            touchedPassword: false,
            touchedRealm: false
        };

        this.realmSelectEl = React.createRef();

        this.onUsernameChanged = this.onUsernameChanged.bind(this);
        this.onPasswordChanged = this.onPasswordChanged.bind(this);
        this.onRealmChanged = this.onRealmChanged.bind(this);
        this.onSignIn = this.onSignIn.bind(this);
    }

    componentDidMount() {
        clearAuthentication();
    }

    componentWillUnmount() {
        this.props.reset();
    }

    onUsernameChanged(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            userName: e.currentTarget.value,
            touchedUsername: true
        });
    }

    onRecoverPassword() {
        history.push("/recover")
    }

    onPasswordChanged(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            password: e.currentTarget.value,
            touchedPassword: true
        });
    }

    onRealmChanged(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            realm: e.currentTarget.value,
            touchedRealm: true
        });
    }

    onEnter = (e : any) => {
        if (e.key === 'Enter') {
            this.onSignIn()
        }
    };

    onSignIn() {
        if (!this.props.fetching) {
            if (this.props.realms && this.props.token) {
                if (this.isValidPhaseTwo()) {
                    this.props.pickRealm(this.props.token, this.state.realm);

                } else {
                    this.setState({
                        touchedRealm: true
                    });
                }

            } else {
                if (this.isValidPhaseOne()) {
                    this.props.login(this.state.userName, this.state.password);

                } else {
                    this.setState({
                        touchedUsername: true,
                        touchedPassword: true
                    });
                }
            }
        }
    }

    private validateUsername() {
        return this.state.userName ? undefined : 'Username Required';
    }

    private validatePassword() {
        return this.state.password ? undefined : 'Password Required';
    }

    private validateRealm() {
        return this.state.realm ? undefined : 'Realm Required';
    }

    private isValidPhaseOne(): boolean {
        return !this.validateUsername() && !this.validatePassword();
    }

    private isValidPhaseTwo(): boolean {
        return !this.validateRealm();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.realms && this.props.realms !== prevProps.realms) {
            // login first stage
            const realm = this.props.realms.get(0);

            this.setState({
                password: '',
                realm: realm ? realm.get('no') : '',
                touchedPassword: false
            });

            this.realmSelectEl.current!.focus();
        }

        if (this.props.success && !prevProps.success) {
            // login second stage
            if(this.props.token){
                window.localStorage.setItem('auth-token', this.props.token);
            }

            const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
            const redirect = urlParams.get('redirect');
            if(redirect){
                window.location.assign(redirect);
            }
        }
    }

    render() {
        const firstPhase = !this.props.realms;
        const fetching = this.props.fetching;

        const errorUsername = this.state.touchedUsername && this.validateUsername();
        const errorPassword = this.state.touchedPassword && this.validatePassword();
        const errorRealm = this.state.touchedRealm && this.validateRealm();

        return (
            <div className="prl-login-page">
                <Container className="prl-login-container">
                    <Card>
                        <CardImg src={pearlchain_logo} className="prl-login-img"/>
                        <CardBody className="prl-login-form">
                            <Form>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <FontAwesomeIcon icon={['fas', 'user']}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="text" id="input-username" placeholder="Username" invalid={!!errorUsername} disabled={!firstPhase || fetching}
                                               value={this.state.userName} onChange={this.onUsernameChanged}/>
                                        <FormFeedback>{ errorUsername }</FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend" >
                                            <InputGroupText>
                                                <FontAwesomeIcon icon={['fas', 'lock']}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="password" id="input-password" placeholder="Password" invalid={!!errorPassword} disabled={!firstPhase || fetching}
                                               value={this.state.password} onKeyPress={this.onEnter} onChange={this.onPasswordChanged}/>
                                        <FormFeedback>{ errorPassword }</FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend" >
                                            <InputGroupText>
                                                <FontAwesomeIcon icon={['fas', 'globe']}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="select" id="input-realm" invalid={!!errorRealm} innerRef={this.realmSelectEl} disabled={firstPhase || fetching}
                                               value={this.state.realm} onKeyPress={this.onEnter} onChange={this.onRealmChanged}>
                                            { this.props.realms && this.props.realms.map((realm, index) =>
                                                <option key={index} value={realm.get('no')}>{ realm.get('description') }</option>
                                            )}
                                        </Input>
                                        <FormFeedback>{ errorRealm }</FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                                <Button color="link" onClick={() => this.onRecoverPassword()}>Forgot Password?</Button>
                                { this.props.error &&
                                <Alert color="danger">
                                    { this.props.error }
                                </Alert> }
                                <div className="flex-justify-center mt-4" >
                                    <Button id="btn-signin" color="primary" type="button"
                                            disabled={this.props.fetching} onClick={this.onSignIn}>Sign In</Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        );
    }
}

export default connect(
    (state: any) => {
        const loginState = getUiState(state).login;
        return {
            realms: loginState.get('realms'),
            token: loginState.get('jwt'),
            success: loginState.get('success'),
            error: loginState.get('error'),
            fetching: loginState.get('fetching')
        };
    },
    { login, pickRealm, reset }
)(LoginForm);
